<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Perjalanan Dinas Luar Provinsi </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateLuarprovinsi">
            <CRow>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_kegiatan"
                  label="Uraian Kegiatan"
                  placeholder="Input Uraian Kegiatan"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_berangkat"
                  type="date"
                  label="Tanggal Pergi"
                  placeholder="Input Tanggal Pergi"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pulang"
                  type="date"
                  label="Tanggal Pulang"
                  placeholder="Input Tanggal Pulang"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="tujuan"
                  label="Tujuan"
                  placeholder="Input Tujuan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_harian"
                  type="number"
                  label="Uang Harian"
                  placeholder="Input Uang Harian"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_transport"
                  type="number"
                  label="Uang Transport"
                  placeholder="Input Uang Transport"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_hotel"
                  type="number"
                  label="Uang Hotel"
                  placeholder="Input Uang Hotel"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="total_uang"
                  type="number"
                  label="Total"
                  placeholder="Input Total"
                  readonly
                />
              </CCol>   
              <CCol sm="6">             
                <div class="form-group">
                <label>Peserta</label>
                <vue-bootstrap-typeahead
                  v-model="id_peserta"
                  :data="pesertas"
                />
                </div>
              </CCol>           
            </CRow>      
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/perjadin/luarprovinsi">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      uraian_kegiatan : "",
      tgl_berangkat : "",
      tgl_pulang : "",
      tujuan : "",
      id_peserta : "",
      uang_harian : 0,
      uang_transport : 0,
      uang_hotel : 0,
      total_uang : 0,
      selected: [], // Must be an array reference!
      pesertas: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showLuarprovinsi();
  },

  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
        .then((response) => {
              // this.pesertas=response.data;
              const datas = response.data;
              
              this.pesertas = datas.map(d=>{return d['nama_pegawai']});;
              console.log("datas::"+this.pesertas)
        });
    },

    updateLuarprovinsi: function(event){

      const luarprovinsi = { 
                          uraian_kegiatan: this.uraian_kegiatan, 
                          tgl_berangkat: this.tgl_berangkat,
                          tgl_pulang: this.tgl_pulang,
                          tujuan: this.tujuan,
                          id_peserta: this.id_peserta,
                          uang_harian: this.uang_harian,
                          uang_transport: this.uang_transport,
                          uang_hotel: this.uang_hotel,
                          total_uang: this.total_uang,
                          jenis_tujuan: 2,
                          id_perjadin: this.$route.params.id_perjadin
                          };

      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"perjadin/"+this.$route.params.id_perjadin, luarprovinsi)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/perjadin/luarprovinsi');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showLuarprovinsi: function(event){
    
      const luarprovinsi = { 
                          uraian_kegiatan: this.uraian_kegiatan, 
                          tgl_berangkat: this.tgl_berangkat,
                          tgl_pulang: this.tgl_pulang,
                          tujuan: this.tujuan,
                          id_peserta: this.id_peserta,
                          uang_harian: this.uang_harian,
                          uang_transport: this.uang_transport,
                          uang_hotel: this.uang_hotel,
                          total_uang: this.total_uang
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"perjadin/"+this.$route.params.id_perjadin)
        .then((response) => {            
              this.uraian_kegiatan=response.data.uraian_kegiatan;
              this.tgl_berangkat=response.data.tgl_berangkat;
              this.tgl_pulang=response.data.tgl_pulang;
              this.tujuan=response.data.tujuan;
              this.id_peserta=response.data.id_peserta;
              this.uang_harian=response.data.uang_harian;
              this.uang_transport=response.data.uang_transport;
              this.uang_hotel=response.data.uang_hotel;
              this.total_uang=response.data.total_uang;
        })
    }
  }
}
</script>